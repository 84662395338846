class Event {
    event = {};
    constructor() {
        this.event = {};
    }

    on(eventName, fn) {
        this.event[eventName] = [];
        this.event[eventName].push(fn);
    }

    close(eventName, fn) {
        if (this.event[eventName]) {
            for (let i = 0; i < this.event[eventName].length; i++) {
                if (this.event[eventName][i] === fn) {
                    this.event[eventName].splice(i, 1);
                    break;
                }
            }
        }
    }

    emit(eventName, data = null) {
        if (this.event[eventName]) {
           if(data) {
               this.event[eventName].forEach(function (fn) {
                   fn(data);
               });
           }
        }
    }
}

export default new Event();
